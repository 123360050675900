<template>
<div class="m-module">
    <div class="section">
        <div class="summary">
            <div class="title">
                <span class="text">{{ $t("lang.estimated") }}</span>
                <span class="icon-eye" :class="{'close':!isView}" @click="toggleView"></span>
                <span class="select-id">
                    <div class="sort" @click="networkBy">
                        <span>{{ networkVos[network].fullName }}</span>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                    <!-- <el-select v-model="value" placeholder="请选择"  @change="selectChanged">
                    <el-option
                        v-for="item in networkVos"
                        :key="item.id"
                        :label="item.fullName"
                        :value="item.id">
                    </el-option>
                   </el-select>  -->
                </span>
            </div>
            <div class="bd">
                <div class="m" v-if="isView">{{btFull.surplusRevoCoin}} {{btFull.btUnit}}  ({{ btFull.lockRevoCoin  }} {{ btFull.btUnit }})</div>
                <div class="m" v-else>***</div>
            </div>
        </div>
        <div class="grids">
            <van-grid :border="false" :column-num="2">
                <!-- @click="$router.push({name:'payment-m'})" -->
                <van-grid-item icon="photo-o">
                    <div class="grid-item" :class="{'disabled':!enabledRecharge}">
                        <div class="item-icon" @click="gotoSelect(1)">
                            <img class="icon" :src="require('@/assets/img/icon_m_add.svg')" />
                        </div>
                        <div class="item-text">{{ $t("lang.deposit") }}</div>
                    </div>
                </van-grid-item>
                <!-- @click="$router.push({name:'withdrawal-m'})" -->
                <van-grid-item icon="photo-o">
                    <div class="grid-item "  :class="{'disabled':!enabledWithdrawal}" >
                        <div class="item-icon" @click="gotoSelect(2)" >
                            <img class="icon" :src="require('@/assets/img/icon_m_send.svg')" />
                        </div>
                        <div class="item-text">{{ $t("lang.withdraw") }}</div>
                    </div>
                </van-grid-item>
                <!-- <van-grid-item icon="photo-o">
                    <div class="grid-item">
                        <div class="item-icon">
                            <img class="icon" :src="require('@/assets/img/icon_m_covert.svg')" />
                        </div>
                        <div class="item-text">Buy Crypto</div>
                    </div>
                </van-grid-item> -->
            </van-grid>
        </div>
        <div class="mod">
            <div class="hd">
                <h3 class="title">{{ $t("lang.wallet") }}</h3>
            </div>
            <div class="bd">
                <div class="chart-container">
                    <line-chart :dataOption="chartData" height="200px"></line-chart>
                </div>
                <div class="filter">
                    <div class="filter-item"  :class="{'active':activeSeven}"  @click="tabSize(7)">7 Days</div>
                    <div class="filter-item"  :class="{'active':activeThirty}"  @click="tabSize(30)">30 Days</div>
                </div>
            </div>
        </div>
        <div class="mod">
            <div class="hd">
                <h3 class="title">
                    {{ $t("lang.funding") }}
                </h3>
                <!-- <div class="extra">
                    <van-icon name="search" />
                </div> -->
            </div>
            <div class="bd">
                <!-- <div class="tools">
                    <van-checkbox shape="round">隐藏0资产</van-checkbox>
                </div> -->
                <div class="list-wrapper">
                    <van-list>
                        <van-cell   >
                            <div class="item">
                                <div class="item-info" >
                                    <div class="item-title">{{ $t("lang.deposit") }}</div>
                                </div>
                                <div class="item-val" @click="$router.push({name:'transaction-m', params:{flowType:'recharge'}})">
                                    <div class="item-num">{{overviewStatsList.rechargeSum}}</div>
                                </div>
                            </div>
                        </van-cell>
                        <van-cell   >
                            <div class="item">
                                <div class="item-info">
                                    <div class="item-title">{{ $t("lang.opting") }}</div>
                                </div>
                                <div class="item-val" @click="$router.push({name:'transaction-m', params:{flowType:'G'}})">
                                    <div class="item-num">{{overviewStatsList.gameGetSum}}</div>
                                </div>
                            </div>
                        </van-cell>
                        <van-cell   >
                            <div class="item">
                                <div class="item-info">
                                    <div class="item-title">{{ $t("lang.TransactionID") }}</div>
                                </div>
                                <div class="item-val" @click="$router.push({name:'transaction-m', params:{flowType:'TX'}})">
                                    <div class="item-num">{{overviewStatsList.expensesSum}}</div>
                                </div>
                            </div>
                        </van-cell>
                        <van-cell   >
                            <div class="item">
                                <div class="item-info">
                                    <div class="item-title">{{ $t("lang.Payouts") }}</div>
                                </div>
                                <div class="item-val" @click="$router.push({name:'transaction-m', params:{flowType:'withdrawal'}})">
                                    <div class="item-num">{{overviewStatsList.withdrawalSum}}</div>
                                </div>
                            </div>
                        </van-cell>
                        <van-cell   >
                            <div class="item">
                                <div class="item-info">
                                    <div class="item-title">{{ $t("lang.gas") }}</div>
                                </div>
                                <div class="item-val" @click="$router.push({name:'transaction-m', params:{flowType:'withdrawal_fees'}})">
                                    <div class="item-num">{{overviewStatsList.gasSum}}</div>
                                </div>
                            </div>
                        </van-cell>
                        <van-cell   >
                            <div class="item">
                                <div class="item-info">
                                    <div class="item-title">{{ $t("lang.LockUp") }}</div>
                                </div>
                                <div class="item-val">
                                    <div class="item-num">{{overviewStatsList.lockRevoCoinSum}}</div>
                                </div>
                            </div>
                        </van-cell>

                        <van-cell   >
                            <div class="item">
                                <div class="item-info">
                                    <div class="item-title">{{ $t("lang.TotalRevenue") }}</div>
                                </div>
                                <div class="item-val" @click="$router.push({name:'teamwork-m'})">
                                    <div class="item-num">{{overviewStatsList.allotSum}}</div>
                                </div>
                            </div>
                        </van-cell>
                       
                        <!-- <van-cell>
                            <div class="item">
                                <div class="item-icon">
                                    <img class="icon" :src="require('@/assets/img/coins/USDT.svg')" />
                                </div>
                                <div class="item-info">
                                    <div class="item-title">TetherUS</div>
                                    <div class="item-desc">USDT</div>
                                </div>
                                <div class="item-val">
                                    <div class="item-num">6.06926837</div>
                                    <div class="item-price">$6.06</div>
                                </div>
                            </div>
                        </van-cell> -->
                    </van-list>
                </div>
            </div>
        </div>
    </div>
    <select-picker
      @close="networkClose"
      @change="networkChange"
      :show="networkVisible"
      :List="networkVos"
      :active="network"
      size="200px"
      :fullName="true"
    ></select-picker>
</div>
</template>
<script>
import Header from "@/components/mobile/header";
import lineChart from "@/views/account/modules/lineChart.vue";
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet,axiosPost} from '@/api/api';
import selectPicker from "@/components/mobile/select-picker.vue";
import EventBus from '@/utils/EventBus';

export default {
    name:'dashboard-m',
    components: {
        Header,
        lineChart,
        selectPicker
    },
    data(){
        return {
            networkVos: [],
            network:0,
            value: '',
            btFull:{},
            page:[],
            api:BASE_GLOB_API,
            url:{
                sendChart:BASE_GLOB_API+'/kanban/serial/chart',
                platform:BASE_GLOB_API+'/kanban/coin/platform',
                info:BASE_GLOB_API+'/kanban/wallet/info',
                page:BASE_GLOB_API+'/kanban/wallet/page',
                overviewStats:BASE_GLOB_API+'/kanban/overviewStats',
            },
            chartData:{},
            isView:true,
            activeSeven:true,
            activeThirty:false,
            networkVisible:false,
            lang: "",
            enabledRecharge:true,
            enabledWithdrawal:true,
            overviewStatsList:{}
        }
    },
    mounted(){
       
    },
    created(){
      this.lang = sessionStorage.getItem("Language")
      this.platform()
    },
    methods:{
        gotoSelect(value){
           if(value ==1){
              if(this.enabledRecharge){
                 this.$router.push({name:'payment-m'})
              }
           }else{
              if(this.enabledWithdrawal){
                 this.$router.push({name:'withdrawal-m'})
              }
           }
        },
        networkChange(i) {
            console.log("network change",i)
            this.fetchUserInfo(this.networkVos[i].id)
            this.value =this.networkVos[i].id
            this.enabledRecharge=this.networkVos[i].enabledRecharge
            this.enabledWithdrawal=this.networkVos[i].enabledWithdrawal
            this.network = i;
            this.networkVisible = false;
            let selectedNet = {
                network:this.network,
                fullName:this.networkVos[i].fullName,
                btPlatformId:this.networkVos[i].id,
                networkName:this.networkVos[i].networkVos[0].networkName,
                btPlatformNetworkId:this.networkVos[i].networkVos[0].id,
                enabledRecharge:this.networkVos[i].enabledRecharge,
                enabledWithdrawal:this.networkVos[i].enabledWithdrawal,
            } 
            EventBus.$emit('enabledRecharge',  this.enabledRecharge);
            EventBus.$emit('enabledWithdrawal', this.enabledWithdrawal);
            window.sessionStorage.setItem("selectedNet", JSON.stringify(selectedNet));
        },
        networkClose() {
            this.networkVisible = false;
        },
        networkBy() {
          this.networkVisible = true;
        },
        platform(){
            axiosGet(this.url.platform).then(res=>{
               if(res.code ==200){
                    let dateList = res.result
                    if(dateList.length > 0){
                        dateList.forEach(item=>{
                         if(item.enabled){
                            let date = {
                                enabled: item.enabled,
                                enabledRecharge:item.enabledRecharge,
                                enabledWithdrawal:item.enabledWithdrawal,
                                fullName:item.fullName,
                                icon:item.icon,
                                id:item.id,
                                networkVos:item.networkVos,
                                shortName:item.shortName,
                                unit:item.unit,
                            } 
                            this.networkVos.push(date)
                            }
                        });
                        this.enabledRecharge=res.result[0].enabledRecharge
                        this.enabledWithdrawal=res.result[0].enabledWithdrawal

                    let selectedNet = JSON.parse( sessionStorage.getItem("selectedNet"));

                    if(selectedNet){
                        console.log("selectedNet",selectedNet);
                        this.value =selectedNet.btPlatformId
                        this.network = selectedNet.network;
                        this.networkVos.forEach(item=>{
                          if(item.id == selectedNet.btPlatformId){
                            let selectedNet = {
                                network:this.network,
                                fullName:item.fullName,
                                btPlatformId:item.id,
                                networkName:item.networkVos[0].networkName,
                                btPlatformNetworkId:item.networkVos[0].id,
                                enabledRecharge:item.enabledRecharge,
                                enabledWithdrawal:item.enabledWithdrawal,
                            } 
                            console.log("selected1",selectedNet)
                            window.sessionStorage.setItem("selectedNet", JSON.stringify(selectedNet));
                          }
                
                      });
                        let selected = JSON.parse( sessionStorage.getItem("selectedNet"));
                        this.enabledRecharge=selected.enabledRecharge
                        this.enabledWithdrawal=selected.enabledWithdrawal
                        this.fetchUserInfo(this.value)
                    }else{
                        this.value =this.networkVos[0].id
                        this.fetchUserInfo(this.value)
                        let selectedNet = {
                            network:0,
                            fullName:this.networkVos[0].fullName,
                            btPlatformId:this.networkVos[0].id,
                            networkName:this.networkVos[0].networkVos[0].networkName,
                            btPlatformNetworkId:this.networkVos[0].networkVos[0].id,
                            enabledRecharge:this.networkVos[0].enabledRecharge,
                            enabledWithdrawal:this.networkVos[0].enabledWithdrawal,
                        } 
                        window.sessionStorage.setItem("selectedNet", JSON.stringify(selectedNet));
                    }

                }
                   
                    // this.value =res.result[0].id 
                   
                    EventBus.$emit('enabledRecharge',  this.enabledRecharge);
                   EventBus.$emit('enabledWithdrawal', this.enabledWithdrawal);
                    // let selectedNet = {
                    //     fullName:res.result[0].fullName,
                    //     btPlatformId:res.result[0].id,
                    //     networkName:res.result[0].networkVos[0].networkName,
                    //     btPlatformNetworkId:res.result[0].networkVos[0].id,
                    //     enabledRecharge:res.result[0].enabledRecharge,
                    //     enabledWithdrawal:res.result[0].enabledWithdrawal,
                    // } 
                    // window.sessionStorage.setItem("selectedNet", JSON.stringify(selectedNet));
                    // this.fetchUserInfo(this.value)
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchUserInfo(value){
            axiosGet(this.url.info,{btPlatformId:value}).then(res=>{
               if(res.code ==200){
                  this.btFull =res.result
                  window.sessionStorage.setItem("btFull", JSON.stringify(this.btFull));
                  this.fetchUserPage( this.btFull)
                  this.fetchUserSendChart(this.btFull)
                  this.overviewStats(this.btFull)
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        overviewStats(value){
            axiosPost(this.url.overviewStats,{btPlatformId:value.btPlatformId,walletId:value.id}).then(res=>{
               if(res.code ==200){
                  this.overviewStatsList = res.result
               
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchUserPage(value){
            axiosPost(this.url.page,{query: {btPlatformId:value.btPlatformId}}).then(res=>{
               if(res.code ==200){
                  this.page =res.result.records
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchUserSendChart(value){
            this.activeSeven=true;
            this.activeThirty=false;
            axiosPost(this.url.sendChart,{query: {btPlatformId:value.btPlatformId}, size:7}).then(res=>{
               if(res.code ==200){
                let data = res.result, xData = [], yData1 = [], yData2 = [], yData3 = [], yData4 = [];
                data.forEach(item=>{
                    xData.push(item.date);
                    yData1.push(item.expensesRevoCoin);
                    yData2.push(item.incomeRevoCoin);
                    yData3.push(item.rechargeRevoCoin);
                    yData4.push(item.withdrawalRevoCoin);
                });
                this.chartData = {
                    title: {
                        //text: "测试表格"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid:{
                        right:'2%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: yData1,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(165,0,254)",
                        },
                    },{
                        data: yData2,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(229,179,60)",
                        },
                    },{
                        data: yData3,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(47,149,220)",
                        },
                    },{
                        data: yData4,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(234,98,98)",
                        },
                    }]
                }
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        tabSize(value){
            if(value ==7){
                this.activeSeven=true;
                this.activeThirty=false;
            }else{
                this.activeSeven=false;
                this.activeThirty=true;
            }
            axiosPost(this.url.sendChart,{query: {btPlatformId:this.value},size:value}).then(res=>{
               if(res.code ==200){
                let data = res.result, xData = [], yData1 = [], yData2 = [], yData3 = [], yData4 = [];
                data.forEach(item=>{
                    xData.push(item.date);
                    yData1.push(item.expensesRevoCoin);
                    yData2.push(item.incomeRevoCoin);
                    yData3.push(item.rechargeRevoCoin);
                    yData4.push(item.withdrawalRevoCoin);
                });
                this.chartData = {
                    title: {
                        //text: "测试表格"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid:{
                        right:'2%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: yData1,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(165,0,254)",
                        },
                    },{
                        data: yData2,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(229,179,60)",
                        },
                    },{
                        data: yData3,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(47,149,220)",
                        },
                    },{
                        data: yData4,
                        type: 'line',
                        itemStyle: {
                            color: "rgb(234,98,98)",
                        },
                    }]
                }
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        toggleView(){
            this.isView = !this.isView;
        }
    }
}
</script>
<style lang="less" scoped>
.m-module {
    .section {
        .summary {
            padding:20px 15px;
            .title {
                display:flex; justify-content: flex-start; align-items: center;
                .text {
                    font-size:11px; margin-right:6px;
                }
                .icon-eye {
                    background:url(~@/assets/img/icon_eye.svg) no-repeat center center/contain; width:14px; height:14px; display:block;
                    &.close {
                        background-image:url(~@/assets/img/icon_closed_eye.svg);
                    }
                }
                .select-id{
                    padding-left: 40px;
                    .sort {
                        padding: 6px 30px;
                        border-radius: 4px;
                        border: 1px solid #ccc;
                        span {
                        margin-right: 5px;
                        color: #666;
                        }
                    }
                }
            }
            .bd {
                .m {
                    font-size:20px; font-weight:600; padding:8px 0;
                }
            }
        }
        .grids {
            margin-bottom:15px;
            .grid-item {
                .item-icon {
                    width:49px; height:49px; margin:0 auto; display:flex; justify-content: center; align-items: center; background:#EAECFF; border-radius:50%;
                    .icon {
                        width:24px; height:24px; display:block;
                    }
                }
                .item-text {
                    text-align:center; font-size:14px; padding:6px 0 0;
                }
                &.disabled {opacity:.6; filter: grayscale(100%);}
            }
        }
        .mod {
            margin-bottom:10px;
            .hd {
                display:flex; justify-content: space-between; align-items: center; padding:5px 15px;
                .title {
                    display:flex; justify-content: flex-start; align-items: center;
                    .title {
                        font-size:16px; font-weight:700;
                    }
                }
            }
            .bd {
                .filter {
                    display:flex; justify-content: center; align-items: center;
                    .filter-item {
                        font-size:14px; line-height:1; padding:6px 10px; border-radius:2px; background:#f3f4f5; margin:0 4px;
                        &.active {
                            background:#EAECFF; color:#4458fe;
                        }
                    }
                }
                .tools {
                    padding:8px 15px;
                }
                .list-wrapper {
                    .item {
                        display:flex; justify-content: space-between; align-items: flex-start;
                        .item-icon {
                            width:36px; height:36px;
                            .icon {
                                width:100%; height:100%;
                            }
                        }
                        .item-info {
                            flex:1; padding:0 10px;
                            .item-title {
                                font-size:16px; font-weight:600;
                            }
                            .item-desc {
                                font-size:12px; line-height:1.3; color:#999;
                            }
                        }
                        .item-val {
                            text-align:right;
                            .item-num {
                                font-size:16px; font-weight:600;
                            }
                            .item-price {
                                font-size:12px; line-height:1.3; color:#999;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>