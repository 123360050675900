<template>
<div class="linechart-modules">
    <v-chart
      class="chart"
      :autoresize="true"
      :option="dataOption"
      :style="{ width: '100%', height: height }"
    />
</div>
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);
export default {
    name:'lineChart',
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    props: {
        height: {
            default: "320px",
            type: String,
        },
        dataOption: {
            default: function () {
                return {};
            },
            type: Object,
        },
    },
    data(){
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.chart {
  width: 100%;
  //width:660px;
  & > div {
    width:100%;
  }
}
</style>